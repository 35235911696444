.container-wynik {
  background-color: #3f2a56;
  color: #ffffff;
  padding: 2rem;
  border-radius: 15px;
  max-width: 800px;
  margin: 2rem auto;
  text-align: center;
}

.title {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
}

.result-image {
  max-width: 50%; /* Adjusted for a smaller image size */
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.result-box {
  background-color: #ffffff;
  color: #3f2a56;
  padding: 0.005rem 2rem; /* Reduced top and bottom padding */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 0.5rem;
  max-width: 800px; /* Keeps width as is */
  margin: 0 auto;
}

.percentage-display {
  font-size: 4rem;
  font-weight: bold;
  color: #8b008b;
  margin: 0.5rem 0; /* Reduced top and bottom margin */
}

.description {
  margin-top: 1rem;
  font-size: 1.2rem;
}

.exact-population {
  margin-top: 0.5rem;
  font-size: 1.3rem;
  color: #3f2a56;
  font-weight: bold;
}


.result-message {
  font-size: 1.5em;
  color: #ffffff;
  margin-top: 2rem;
  background-color: #8b008b;
  padding: 1rem;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.global-button {
  background-color: #8b008b; /* Fun, bold color */
  color: #ffffff;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 50px; /* Rounded for a softer look */
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: transform 0.2s ease, background-color 0.3s ease;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  display: inline-flex;
  align-items: center;
  gap: 0.5rem; /* Space for emoji */
}

/* Hover effects */
.global-button:hover {
  background-color: #7a007a;
  transform: translateY(-3px); /* Lift effect */
  box-shadow: 0px 12px 20px rgba(0, 0, 0, 0.15);
}

/* Active state for "press" effect */
.global-button:active {
  transform: translateY(2px);
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
}

.result-message a {
  color: #000000;
  font-weight: bold;
  text-decoration: none;
  padding: 0.2em 0.4em;
  border-radius: 4px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.result-message a:hover {
  background-color: #ff69b4;
  color: white;
  text-decoration: underline;
}

.calculated-by {
  font-size: 0.8rem;
  color: #888888;
  margin-top: 1rem;
  text-align: center;
}

.calculated-by a {
  color: #8b008b;
  text-decoration: none;
}

.calculated-by a:hover {
  text-decoration: underline;
}