/* Container for the FAQ section */
.faq-container {
    background-color: #32204a;
    padding: 2rem;
    border-radius: 8px;
    max-width: 800px;
    margin: 2rem auto;
    color: #e0b3ff;
    font-family: Arial, sans-serif;
  }
  
  /* FAQ Title */
  .faq-title {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 1.5rem;
    color: #e0b3ff;
  }
  
  /* Individual FAQ item */
  .faq-item {
    border-bottom: 1px solid #6a4d8a;
    padding: 1rem 0;
  }
  
  .faq-item:last-child {
    border-bottom: none;
  }
  
  /* Question styling */
  .faq-question {
    font-size: 1.2rem;
    font-weight: bold;
    cursor: pointer;
    color: #d1a3ff;
    transition: color 0.3s ease;
  }
  
  .faq-question:hover {
    color: #e6ccff;
  }
  
  /* Answer styling */
  .faq-answer {
    background-color: transparent;
    font-size: 1rem;
    color: #e0e0e0;
    margin-top: 0.5rem;
    line-height: 1.6;
    display: none;
    padding: 0.5rem 0 0.5rem 1rem;
  }
  
  /* Expand icon for question */
  .faq-question::after {
    content: '+';
    float: right;
    font-weight: bold;
    transition: transform 0.3s ease;
  }
  
  /* Rotate icon when expanded */
  .faq-question.expanded::after {
    content: '-';
    transform: rotate(180deg);
  }
  
  /* Smooth accordion effect */
  .faq-answer.show {
    display: block;
    animation: slideDown 0.3s ease;
  }
  
  /* Slide-down animation for expanding */
  @keyframes slideDown {
    from {
      opacity: 0;
      max-height: 0;
    }
    to {
      opacity: 1;
      max-height: 500px; /* Adjust based on answer length */
    }
  }
  .about-container {
    background-color: #f9f9f9; /* Soft background for separation */
    color: #333; /* Standard readable text color */
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    margin: 2rem 0;
  }
  
  .about-title {
    font-size: 2rem;
    margin-bottom: 1.5rem;
    color: #8b008b; /* Add some personality to the title */
    text-align: center;
  }
  
  .about-description {
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 1rem;
  }
  
  .about-quote {
    font-style: italic;
    background-color: #fff3cd; /* Highlighted background */
    border-left: 4px solid #ffc107; /* Matching border color */
    margin: 1rem 0;
    padding: 0.5rem 1rem;
    font-size: 1.1rem;
    color: #856404; /* Soft contrasting color for readability */
  }