body {
  background-color: #563d6e; /* Slightly lighter than #3f2a56 */
  color: #ffffff;
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.container-standardow {
  background-color: #3f2a56;
  color: #ffffff;
  padding: 2rem;
  border-radius: 15px;
  max-width: 800px;
  margin: 2rem auto;
  text-align: center;
}

.title {
  font-size: 3rem; /* Larger font size for emphasis */
  color: #ff1493; /* Vibrant pink color */
  font-weight: bold;
  text-align: center;
  text-shadow: 1px 1px 3px #8b008b, 0 0 3px #ff69b4, 0 0 6px #ff1493;
  margin-top: 0.5rem;
  padding: 1rem;
  background: linear-gradient(45deg, #ffe4e1, #ffb6c1); /* Soft gradient background */
  border-radius: 12px;
  animation: pulse 2s infinite; /* Adds a pulse effect */
}

/* Adding a simple pulsing animation */
@keyframes pulse {
  0%, 100% {
      transform: scale(1);
      opacity: 1;
  }
  50% {
      transform: scale(1.05);
      opacity: 0.95;
  }
}

.subtitle {
  font-size: 1.3rem; /* Larger font for emphasis */
  line-height: 2; /* Increase spacing for readability */
  color: #ff69b4; /* A vibrant pink color */
  text-shadow: 1px 1px 2px #333, 0 0 1em #ff69b4, 0 0 0.2em #8b008b;
  font-weight: bold;
  margin-top: 1rem;
  padding: 1rem 1rem;
  border-radius: 10px;
  background-color: #fdf2f8; /* Light background for contrast */
}

.subtitle emoji {
  font-size: 1.5rem; /* Make emojis slightly larger for emphasis */
}

.calculator-box {
  background-color: #ffffff;
  color: #3f2a56;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.option-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.option-label {
  flex: 1;
  text-align: left;
}

.option-group {
  display: flex;
  gap: 10px;
}

.option-btn {
  background-color: #eeeeee;
  color: #3f2a56;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.option-btn.selected {
  background-color: #3f2a56;
  color: #ffffff;
}

.option-btn:hover {
  background-color: #dddddd;
}


/* Height and Income Sliders */
.track-min {
  background: #8b008b; /* Fill for the minimum sliders */
  height: 8px;
  border-radius: 4px;
}

.range-slider {
  width: 100%;
  height: 8px;
  background: #ddd;
  border-radius: 4px;
  margin: 10px 0;
  position: relative;
}

.thumb {
  height: 20px;
  width: 20px;
  background-color: #3f2a56;
  border-radius: 50%;
  cursor: pointer;
  border: 2px solid #ffffff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.track {
  height: 8px;
  border-radius: 4px;
  position: absolute;
}

/* Age Range Slider */
.track-age {
  background: #ddd; /* Default background for the entire track */
  height: 8px;
  border-radius: 4px;
  position: absolute;
}

.age-slider .track-age-0 {
  background: #ddd; /* Unselected part before the first thumb */
}

.age-slider .track-age-1 {
  background: #8b008b; /* Selected part between the two thumbs */
}

.age-slider .track-age-2 {
  background: #ddd; /* Unselected part after the second thumb */
}

/* CSS for the height and income minimum sliders */
.track-min-0 {
  background: #8b008b; /* Fill up to the single handle */
}

.track-min-1 {
  background: #ddd; /* Remaining part of the track after the handle */
}

.global-button {
  margin-top: 2rem;
  background-color: #8b008b; /* Fun, bold color */
  color: #ffffff;
  padding: 1rem 1.5rem;
  border: none;
  border-radius: 50px; /* Rounded for a softer look */
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: transform 0.2s ease, background-color 0.3s ease;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  display: inline-flex;
  align-items: center;
  gap: 0.5rem; /* Space for emoji */
}

/* Hover effects */
.global-button:hover {
  background-color: #7a007a;
  transform: translateY(-3px); /* Lift effect */
  box-shadow: 0px 12px 20px rgba(0, 0, 0, 0.15);
}

/* Active state for "press" effect */
.global-button:active {
  transform: translateY(2px);
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
}

.footer-text {
  font-size: 0.75rem;
  margin-top: 1rem;
  color: #dddddd;
}

.container-wynik {
  background-color: #3f2a56;
  color: #ffffff;
  padding: 2rem;
  border-radius: 15px;
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
}

.result-box {
  background-color: #ffffff;
  color: #3f2a56;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 1.5rem;
}

.percentage-display {
  font-size: 4rem;
  font-weight: bold;
  color: #8b008b;
}

.description {
  margin-top: 1rem;
  font-size: 1.2rem;
}
.faq-container {
  margin-top: 2rem;
  padding: 1.5rem;
  background-color: #f2f2f2;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.faq-title {
  font-size: 1.8rem;
  color: #333;
  margin-bottom: 1rem;
  text-align: center;
}

.faq-item {
  margin-bottom: 1rem;
}

.faq-question {
  width: 100%;
  background: #3f2a56;
  color: #ffffff;
  font-size: 1.1rem;
  padding: 0.8rem;
  border: none;
  border-radius: 5px;
  text-align: left;
  cursor: pointer;
  transition: background 0.3s ease;
}

.faq-question:hover {
  background: #8b008b;
}

.faq-answer {
  padding: 0.8rem;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-top: 0.5rem;
}

.footer-contact {
  text-align: center;
  margin-top: 2rem;
  padding: 1rem;
  font-size: 0.9rem;
  color: #ffffff;
  background-color: #3f2a56;
  border-top: 1px solid #8b008b;
}

.footer-contact a {
  color: #ff69b4;
  text-decoration: none;
}

.footer-contact a:hover {
  text-decoration: underline;
}